<template>
  <span v-if="carbsSpecified" data-testid="insulin-dose-actual"
    >{{ carbsConsumed }} g
  </span>
  <v-chip
    v-else-if="carbsMissing"
    label
    small
    color="#FDF7E6"
    text-color="#333"
    class="width-100-pct align-center justify-center"
    data-testid="bmg-chip-msg"
  >
    <span
      :class="{
        'deleted-dose-color': isDeleted,
      }"
    >
      {{ landscape ? 'Unknown carbs' : 'Unknown' }}
    </span>
  </v-chip>
  <div v-else class="background fill-height" />
</template>
<script>
export default {
  name: 'InsulinDiaryCarbsConsumed',
  props: {
    carbsConsumedType: { type: String, required: true },
    carbsConsumed: { type: Number, required: false },
    landscape: { type: Boolean, required: true },
    isDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    carbsSpecified() {
      return this.carbsConsumedType && this.carbsConsumedType === 'specified'
    },
    carbsMissing() {
      return this.carbsConsumedType && this.carbsConsumedType === 'idk'
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  background-color: $nn-TB_T98;
}
</style>
